<template>
  <div class="admin-import-users">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        <!-- <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/users')"
          icon="left"
        ></a-button> -->

        <admin-org-indicator></admin-org-indicator>

        Admin - Imports for {{ selectedOrganisation.displayName }}
      </h1>
      <div class="actions">
        <a-button
          @click.prevent="loadImports"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >

        <a-button
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          @click.prevent="$router.push('/admin/users/imports/create')"
          >Upload</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Not loading -->
    <div v-if="!isLoading">
      <!-- No imports -->
      <a-alert
        v-if="imports.length == 0"
        type="info"
        message="No imports to show"
      />
      <!-- / No imports -->

      <a-table
        v-if="imports.length"
        :columns="columns"
        :data-source="importsForTable"
        :pagination="false"
        bordered
      >
      </a-table>
    </div>
    <!-- / Not loading -->
  </div>
</template>

<script>
const _ = require('lodash');
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';

const COLUMNS = [
  {
    title: "Source",
    dataIndex: "sourceHuman",
  },
  {
    title: "Status",
    dataIndex: "status"
  },
  {
    title: "When",
    dataIndex: "when"
  },
  {
    title: "Rows",
    dataIndex: "rows",
  },
  {
    title: "Applied",
    dataIndex: "applied",
  },
  {
    title: "Skipped",
    dataIndex: "skipped",
  },
  {
    title: "Failed",
    dataIndex: "failed",
  },
];

export default {
  data() {
    return {
      columns: COLUMNS,
    };
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadImports();
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    ...mapGetters("adminUserImports", {
      imports: "imports",
      isLoading: "isLoading",
    }),

    importsForTable() {
      if (this.imports && this.imports.length) {
        return _.map(this.imports, userImport => {
          let ui = {...userImport};

          // When
          ui.when = ui.submittedInstant ? moment.utc(ui.submittedInstant).format('dddd Do MMMM Y, HH:mm') : 'Unknown';

          // Determine type
          if (ui.importType == 1) {
            ui.sourceHuman = 'Excel';
          } else {
            ui.sourceHuman = 'Unknown';
          }

          // Determine status
          if (ui.exceptionMesssage) {
            ui.status = 'Error: ' + ui.exceptionMesssage;
          } else if (ui.completedInstant) {
            ui.status = 'Complete' ;
          } else if (ui.startInstant || ui.submittedInstant) {
            ui.status = 'In progress';
          } else {
            ui.status = 'Unknown';
          }

          // Applied
          if (ui.created !== undefined && ui.updated !== undefined) {
            ui.applied = ui.created + ui.updated;
          } else {
            ui.applied = null;
          }

          return ui;
        });
      }
      return [];
    },
  },

  methods: {
    ...mapActions("adminUserImports", {
      loadImports: "loadImports",
    }),
  },
};
</script>

<style lang="scss">
.admin-import-users {
  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .import-list-item {
    margin-bottom: 15px;
  }

  .button-margin-left {
    margin-left: 15px;
  }

  .ant-table {
    background: #fff !important;
  }

  padding-bottom: 50px;
}
</style>